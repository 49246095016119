import allotInput from './allot/index.vue'
import allotFX from './allotFX/index.vue'
export default {
  name: "",
  components: {
    allotInput,
    allotFX
  },
  data() {
    return {
      type:'allotInput',
    };
  },
  mounted(){
    // if(this.$route.query.id) {
    //   this.type = 'allotFX'
    // }

    if(this.$route.query.orderType == "purchaseWarehousing" || this.$route.query.id) {
      this.orderType == 'allotFX'
      this.changeType( 'allotFX')
    } else {
      this.orderType == 'allotInput'
      this.changeType( 'allotInput')
    }
  },
  methods:{
    
    changeType(type){
      this.type = type
    }
  }
}