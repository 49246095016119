import Step from "@/components/global/Step/Step.vue";
import ProductList from "./../components/ProductList";
import {
  getOrderDetail,
  sumbitReturnOrder,
  // 模糊搜索订单号
  getOrderNUmSeach,
  getOrderNUmSeachQd,
  getWarehouseListApi,
  getFXOrderDetail,
  FenxiaoRuturn
} from "./api";
export default {
  name: "Home",
  components: {
    Step,
    ProductList
  },

  data() {
    return {
      steps: ["录入", "提交", "查看进度"],
      currentStep: 2,
      productList: [],
      productInfo: undefined,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/channelReturns/returnEntry",
          name: "",
          title: "发起退货"
        }
      ],
      isLoading: false, // 提交按钮loading
      pageLoadFlag: false,
      message: "", //备注
      orderNum: '',
      ordernumList: [],
      isFenxiao: false,
      warehouseList: [],
      warehouseSelectSHow: false,
      warehouseName: '',
      warehouseId: '',
      yrckID: '',
	  t: 500, //倒计时
    };
  },
  props: {
    orderType: {
      type: String,
      required: true
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if(val.query.orderType == "purchaseWarehousing") {
          this.orderType == 'allotFX'
          this.orderChange( 'allotFX')
        } else {
          this.orderType == 'allotInput'
          this.orderChange( 'allotInput')
        }
      },
      // 深度观察监听
      deep: true
    },
  },
  mounted() {
    if (this.isFenxiao) {
      this.getWarehouseList()
    }
    if(this.$route.query.document){
      this.orderNum = this.$route.query.document.trim()
      this.getorders(this.orderNum);
      this.search(this.orderNum)
    }

  },
  methods: {
    // 切换状态
    orderChange(type) {
      this.$emit("changeType", type);
    },
    // 获取仓库
    getWarehouseList() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        // orgId: this.orgId,
        warehouseType: 70
      };
      getWarehouseListApi(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.warehouseList = data.data.map(item => {
          return {
            value: item.cId,
            name: item.name
          };
        });
        if (this.warehouseList.length > 0) {
          this.warehouseName = this.warehouseList[0].name;
          this.warehouseId = this.warehouseList[0].value;
          this.yrckID = this.warehouseList[0].name;
          this.warehouseChecked = true;
        } else {
          this.warehouseName = "";
          this.warehouseId = "";
          this.yrckID = "";
        }
      });
    },
    //仓库前段筛选
    filterWarehouseOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 仓库下拉框选择
    handleChangeWarehouse(value, e) {
      this.warehouseId = e.key;
      this.warehouseName = value;
      this.warehouseSelect();
    },
    //仓库选择
    warehouseSelect() {
      this.warehouseSelectSHow = !this.warehouseSelectSHow;
    },
    // 输入订单号模糊搜索
    getorderNumList(value) {
        this.productList = []
        this.orderNum = value
		if(this.t < 500) return
		if (!this.orderNum || this.orderNum.length < 6) {
		  // this.$message.destroy()
		  // this.$message.info("请至少输入订单号6位进行查询。");
		  return
		} else {
			this.getorders(value);
			let timmer = setInterval(() => {
				this.t --;
				if(this.t <= 0) {
					this.t = 500;
					clearInterval(timmer);
					this.getorders(value);
				}
			},1)
		}
    },
	getorders(value) {
		if (this.isFenxiao) {
		  let data = {
		    cisCode: this.$store.state.user.userInfo.customer.cisCode,
		    terms: {
		      documentNum: this.orderNum
		    },
		    page: {
		      pageNo: 1,
		      pageSize: 200
		    }
		  }
		  getOrderNUmSeachQd(data).then(res => {
		    let data;
		    if (typeof res.data == "string") {
		      data = JSON.parse(res.data)

		    } else {
		      data = res.data;
		    }
		    this.ordernumList = [...new Set(data.data)]; //缓存列表数据，前端分页

		  })
		} else {
		  let data = {
		    cisCode: this.$store.state.user.userInfo.customer.cisCode,
		    documentNum: this.orderNum
		  }
		  getOrderNUmSeach(data).then(res => {
		    let data;
		    if (typeof res.data == "string") {
		      data = JSON.parse(res.data)

		    } else {
		      data = res.data;
		    }
		    this.ordernumList = [...new Set(data.data)]; //缓存列表数据，前端分页

		  })
		}
	},
    // 根据订单查询订单详情
    search() {
      if (this.isFenxiao) {
          this.fenxiaoDetail()

      } else {
        this.getOrderContent()
      }
    },
    // 查询订单详情
    getOrderContent() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum: this.orderNum
      }
      getOrderDetail(data).then(res => {
        if(res) {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.productList = data.data.itemList
          // 需要传回去的参数
          this.productInfo = data.data
        } else {
          this.$message.warning('商品详情未查询到，请选择其他订单')
        }
      })
    },
    fenxiaoDetail() {
      let orderid = ''
      this.ordernumList.forEach(item=>{
        if(item.documentNum == this.orderNum) {
          orderid = item.purchaseOrderId
        }
      })

      let data ={
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        purchaseOrderId: orderid
      }
      getFXOrderDetail(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.productList = data.data.returnOrderItemList
        // 需要传回去的参数
        this.productInfo = data.data
      })
    },


    //  退货提交
    sumbitApply() {
      let amountTotal = 0
      this.productList.forEach(it => {
        amountTotal += it.amount
      });
        if(!this.orderNum) {
          this.$message.warning('请输入订单号')
          return
        }
        let ProductItem = []
        this.productList.forEach(item=>{
          if(item.borderedQty > 0) {
            ProductItem.push({
              model: item.model,
              productCode: item.productCode,
              lineNum: item.lineNum,
              borderedQty: item.borderedQty, // 退货数量
              imperfectQty: 0,
              ordinaryQty: 0,
              bprice: item.bprice, // 价格
              amount: item.borderedQty * item.bprice
            })
          }
        })
        let data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          returnOrder: {
            documentNum: this.productInfo.documentNum,
            userId: this.productInfo.userId,
            customerCode: this.productInfo.customerCode,
            sellerCode: this.productInfo.sellerCode,
            storeCode: this.productInfo.storeCode,
            message: this.message,
            amount: amountTotal,
            itemList: ProductItem
          }
        }
        if(ProductItem.length <= 0) {
          this.$message.warning('至少有一件退货商品')
          return
        }
        this.isLoading = true
        sumbitReturnOrder(data).then(res => {
          const data = JSON.parse(res.data);
          if (data.code == 0) {
            this.$router.push({
              path: "/channelReturns/returnSubmit",
              query: {
                b2bOrderCode: data.msg,
              }
            });
            this.isLoading = false
          } else {
            this.$message.warning(data.msg || '提交失败，请重新选择订单进行退货');
            this.isLoading = false
          }
        })
    },

    editRow() {
      this.rowSelectionType = "radio";
      this.modelShow = true;
    },
    goBack() {
      this.$router.push('/index');
    }
  }
};
