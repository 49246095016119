<template>
  <div>
    <div class="prod-list-wrap">
      <div class="title-wrap">商品信息</div>
      <div class="product-list-wrap clearfix">
        <div
          class="commodity-item"
          v-for="(item, index) of productList"
          :key="index"
        >
          <div class="commodity-item-check">
<!--            <a-checkbox-->
<!--              :checked="item.checked"-->
<!--              @change="itemClick(item, $event)"-->
<!--            ></a-checkbox>-->
          </div>
          <div class="commodity-item-detail">
            <h-img2
              class="pro-img"
              :imgUrl="'/product/pic.nd?productId=' + item.productCode"
              :errorImg="
                '/assets/new/images/product/' + item.materialGroupCode + '.jpg'
              "
              :isUseBasePath="true"
              alt=""
            />
            <div class="commodity-item-title">
              <p>{{ item.model }}</p>
              <span class="itemOne">品牌：{{ item.brand }}</span>
              &nbsp;&nbsp;&nbsp;
              <span>颜色：{{ item.colour }}</span>
              <br />
              <span>体积：{{ item.volume }}m³</span>
            </div>
          </div>
          <div class="commdity-item-jiage">
            <p class="sizeLevel" >
              <span>质量等级：{{ item.invStatusName }}</span>
<!--              <span>补差类型：{{ item.invStatusTypeName }}</span>-->
            </p>
          </div>
          <div class="commdity-item-jiage">
            <p class="sizeLevel" >
              <span>仓库：{{ item.gicOutWarehouseName }}</span>
            </p>
          </div>
          <div class="commdity-item-jiage commdity-item-total">
            <!--            <span @click="edit(item)">编辑</span>-->
            <!-- 删除 -->
            <!-- <div class="close">
              <a-popconfirm
                placement="topRight"
                title="确认删除该商品？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delProduct(index)"
              >
                <i class="iconfont icon-guanbi-gouwuche"></i>
              </a-popconfirm>
            </div> -->
          </div>
          <div
            class="typeing-wrap"
            :style="{ width:  '470px' }"
          >
            
            <span class="lab">退货数量：</span>
            <number-box
              class="number-box"
              :curVal.sync="item.borderedQty"
              :maxVal="item.backnowledgedQty"
            />
            <span class="lab">价格：</span>
           
            <a-input-number :disabled = "typeFx=='Fx'" :min="0" v-model="item.bprice" />
            
          </div>
        </div>
      </div>
      <!-- <div class="modelClick" @click="openAdd">+新增商品</div> -->
       <div v-if="productList.length <= 0">
          <img style="width:200px" src="./../../../../assets/order/noList.png" alt="" />
          <p style="color:#777">暂无订单信息，请先输入订单号查询~</p>
        </div>
    </div>
    <div class="sum-wrap-wrap">
      <div class="sum-wrap">
        <p class="info">
          共 {{ getTotalProd() }} 件商品 体积合计{{
            $util.getFloat(getTotalVol(), 3)
          }}m³
          <a-popover placement="topRight">
            <template slot="content">
              <p>起运量标准：单电视商家5台，白电及全品类商家3m³</p>
            </template>
            <template slot="title">
              <span>物流费用规则</span>
            </template>
            <i class="iconfont icon-tijiwenti-pc problemfang"></i>
          </a-popover>
        </p>
        <!--        <p class="info">-->
        <!--          合计：-->
        <!--          <span>{{ $util.getFloat(getTotalMoney(), 2) }}元</span>-->
        <!--        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getInvQty } from "./api.js";

export default {
  name: "ProductList",
  props: {
    productList: {
      type: Array,
      required: true
    },
    typeFx: {
      type: String,
      required: true
    }
   
  },
  data() {
    return {
      invStatusList: [],
      qualitylvList: [],
    };
  },

  methods: {
    getInvQtyList(item) {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        productCode: item.productCode,
        warehouseId: this.warehouseId,
        invStatusType: item.invStatusType,
        invStatusId: item.invStatus,
        invBatchId: ""
      };
      getInvQty(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // item.maxQty = data.bavailqty
        // item.bigQty = data.bavailqty
      });
    },
    // 选中商品
    itemClick(item, e) {
      item.checked = e.target.checked;
      this.$forceUpdate();
    },
    //删除商品
    delProduct(index) {
      this.productList.splice(index, 1);
    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (Number(item.borderedQty) || 0);
        } else {
          return sum + (Number(item.borderedQty) || 0);
        }
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
         return sum + (item.borderedQty || 0) * (item.volume  || 0);
        // if (item.checked) {
        //   return sum + (item.borderedQty || 0) * (item.dmsProductModel.volume  || 0);
        // } else {
        //   return sum;
        // }
      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        if (item.checked && !isNaN(item.bprice)) {
          return sum + (item.borderedQty || 0) * (item.bprice || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getxj(bprice, borderedQty, item) {
      item.amount = new Number((bprice || 0) * (borderedQty || 0)).toFixed(2);
      return item.amount;
    },
    openAdd() {
      this.$emit("openAdd");
    },
    //编辑
    edit(item) {
      this.$emit("edit", item);
    }
  }
};
</script>

<style lang="less" scoped>
.prod-list-wrap {
  width: 1188px;
  margin: 16px auto;
  margin-bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  box-sizing: border-box;

  .title-wrap {
    line-height: 22px;
    padding-top: 24px;
    padding-left: 24px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
  }

  .product-list-wrap {
    width: 1140px;
    margin: 0 auto;

    div {
      display: inline-block;
    }

    .commodity-item {
      position: relative;
      width: 1140px;
      height: 215px;
      border: 1px solid #eeeeee;
      margin-top: 16px;
      padding: 22px 24px;
      box-sizing: border-box;
      float: left;
      //display: flex;
      //justify-content: flex-start;
      // align-items: center;
      .typeing-wrap {
        position: absolute;
        background: #f2f3f5;
        width: 1077px;
        height: 52px;
        bottom: 18px;
        left: 48px;
        line-height: 52px;
        text-align: left;

        &:after {
          box-sizing: content-box;
          width: 0;
          height: 0;
          position: absolute;
          top: -27px;
          left: 48px;
          padding: 0;
          border-bottom: 14px solid #f2f3f5;
          border-top: 14px solid transparent;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          display: block;
          content: "";
          z-index: 10;
        }

        .lab {
          margin-left: 24px;
        }

        .chose-account {
          width: 150px;
        }

        .number-box {
          width: 170px;
          margin-left: 24px;
        }
      }
    }

    .commodity-item-check {
      //width: 30px;
      min-height: 20px;
      margin-right: 24px;
      line-height: 98px;
      float: left;

      /deep/ .ant-checkbox-input {
        width: 20px;
        height: 20px;
      }
    }

    .commodity-item-detail {
      margin-right: 0px;
      width: 400px;
      float: left;

      .pro-img {
        width: 100px;
        height: 100px;
        margin-right: 16px;
        float: left;
      }

      .commodity-item-title {
        float: left;
        text-align: left;

        p {
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          margin-top: 15px;
          margin-bottom: 8px;
        }

        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;

          .itemOne {
            margin-right: 10px;
          }
        }
      }
    }

    .commdity-item-jiage {
      // width: 200px;
      margin-top: 15px;
      margin-right: 60px;
      float: left;

      .size {
        float: left;
        margin-bottom: 11px;

        input {
          display: inline-block;
          width: 80px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #cccccc;
          text-align: center;
          line-height: 28px;
          margin-right: 5px;
        }

        /deep/ .ant-input:focus {
          border-color: rgba(0, 170, 166, 1) !important;
        }
      }

      .sizeLevel {
        margin-top: 32px;
        font-size: 12px;
        font-weight: 400;
        color: #aaaaaa;

        span {
          margin-right: 10px;
        }
      }
    }

    .commdity-item-num {
      margin-top: 10px;

      .number-box {
        height: 38px;
        float: left;
        margin-bottom: 6px;

        // margin-bottom: 11px;
        /deep/ .btn-number {
          line-height: 40px;
        }
      }

      .sizeLevel {
        span {
          margin-right: 10px;
        }
      }
    }

    .commdity-item-total {
      text-align: left;
      float: right;
      margin-right: 0;
      text-align: left;
      float: right;
      margin-right: 0;
      display: flex;
      width: 100px;
      justify-content: flex-end;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 5px;
      }

      span {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        color: #777777;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
      }
    }

    .close {
      // margin-top: 30px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #aaaaaa;
      // background-color: rgb(110, 112, 112);
      cursor: pointer;

      i {
        font-size: 20px;
      }
    }

    .close:hover {
      // background-color: #00AAA6;
      background-image: url(../../../../assets/cart/close.png);
      color: #fff;
      background-size: 100%;
    }
  }

  .modelClick {
    width: 460px;
    height: 38px;
    background: #ffffff;
    border: 1px dashed #cccccc;
    margin: 16px auto;
    margin-bottom: 0;
    line-height: 38px;
    font-size: 14px;
    font-weight: 400;
    color: #1890FF;;
    cursor: pointer;
  }
}

.sum-wrap-wrap {
  width: 1188px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  // margin-bottom: 16px;

  .sum-wrap {
    width: 1140px;
    background: #f7f7f7;
    margin: 0 auto;
    padding: 17px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      text-align: right;
      color: #777777;
      font-size: 12px;
      line-height: 24px;

      span {
        color: #262626;
      }

      .problemfang {
        margin-left: 6px;
        color: #0caeaa;
        cursor: pointer;
      }

      .yufu {
        margin-right: 80px;
      }
    }
  }
}
</style>
