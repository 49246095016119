import dmshttp from "@/utils/dmsrequest";

// 模糊搜索订单号
export function getOrderNUmSeach(data) {
  return dmshttp({
    url: "returnTipsSalesOrder",
    data
  });
}

//订单查询
export function getOrderDetail(data) {
  return dmshttp({
    url: "returnShowSalesOrder",
    data
  });
}

// 仓库列表
export function getWarehouseListApi(data) {
  return dmshttp({
    url: "getWarehouseList",
    data
  });
}

// 退货录入（确认退货）
export function sumbitReturnOrder(data) {
  return dmshttp({
    url: "channelCreationReturn",
    data
  });
}
// 分销商按单退货（确认退货）
export function FenxiaoRuturn(data) {
  return dmshttp({
    url: "createReturnOrderByPurchaseOrder",
    data
  });
}

export function getOrderNUmSeachQd(data) {
  return dmshttp({
    url: "purchaseOrderReturnList",
    data
  });
}

//订单查询
export function getFXOrderDetail(data) {
  return dmshttp({
    url: "purchaseOrderReturnDetail",
    data
  });
}


